* {
  box-sizing: border-box;
}

html,body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1 {
  margin: 0;
}

li[disabled] {
  color: #ccc !important;
  pointer-events: none;
}

li[disabled] .bus-num {
  background: #ccc !important;
}

.departure-time {
  padding: 0px 0px 0px 2px;
  display: flex;
  flex-wrap: wrap;
}

.circle-icon {
  font-size: 7px;
}


.clearfix :after {
  display: table;
  height: 0;
  content: '';
  clear: both;
}

.container {
  height: 100%;
}

.main-header {
  padding: 5px 10px;
  display: table;
  width: 100%;
}

.logo {
  display: inline-block;
  margin-right: auto;
}

.logo img {
  vertical-align: middle;
  margin-right: 20px;
}

.logo a {
  display: block;
}

.gm-ui-hover-effect {
    border: none !important;
}

.route-name {
  display: inline-block;
  margin-left: 50px;
  color: #dc3131;
  font-size: 18px;
}

.main-content {
  position: relative;
  height: calc(100% - 63px);
}

/* menu */

.menu {
  font-size: 30px;
  text-align: right;
  margin-right: 20px;
  display: none;

  right: 0;
  top: 13px;
  position: absolute;
}


/* bus-route */

.aside {
  width: 425px;
  float: left;
  padding: 0 10px;
  height: 100%;
  overflow: scroll;
}

.bus-box {
  padding-bottom: 130px;
  cursor: not-allowed;
}

.info {
  font-size: 13px;
  color: dimgray !important;
  padding: 6px 0px 0px 6px !important;
  font-weight: bold;
}

.bus-box li {
  padding: 6px;
  color: #dc3131;
  cursor: pointer;
}
.route {
  padding-bottom: 0px !important;
  font-size: 15px;
}

.pill:hover {
  filter: brightness(1.1);
  cursor: default;
}


.bus-box .bus-route-num-box:nth-child(odd) {
  background: #eee;
}

.bus-box .bus-route-num-box:nth-child(even) {
  background: #dddd;
}

.bus-num {
  display: inline-block;
  padding: 1px;
  width: 40px;
  text-align: center;
  background: #dc3131;
  color: #fff;
  font-weight: bold;
  margin-right: 10px;
}


.bus-num-box {
  width: 65px;
  float: left;
}

.bus-route {
  margin-left: 65px;
}

.bus-box li:hover .bus-route, .stop-name:hover {
  text-decoration: underline;
}

.active {
  color: #000;
}

/* map */

.map {
  margin-left: 400px;

  height: 100%;
}


@media only screen and (max-width: 1023px){

  .logo {
    display: block;
  }

  .route-name {
    display: block;
  }

  .menu {
    /*display: block;*/
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .arrow-icon {
    padding-right: 5px;
  }

  .route-name {
    min-height: 23px;
  }

  .aside {
    /*display: none;*/
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;

    background: #fff;
  }

  .map {
    width: 100%;
    margin-left: 0;
  }

  .slide-enter-active, .slide-leave-active {
    transition: all .3s cubic-bezier(.65, .05, .36, 1);
  }
  .slide-enter, .slide-leave-active {
    left: -100%;
  }

}

@media only screen and (max-width : 768px) {
  .aside {
    width: 100%;
  }

  .rpk-logo {
    width: 70vw;
  }

  .route-name {
    font-size: 14px;
    text-align: left;
    margin-left: 0;
    padding-right: 35px;
  }

  .main-content {
    height: calc(100% - 56px);
  }

  /*.menu {*/
  /*  margin-top: 15px;*/
  /*}*/

  .right {
    font-size: 12px;
  }

}

.pill {
  background: #7e8382;
  border: 1px solid transparent;
  border-radius: 0.6em;
  color: #fff;
  font-weight: 600;
  padding: 0 0.5em;
  margin-right: 3px;
  margin-top: 0.7vh;
}

.closestTime {
  font-weight: bold;
  border-color: #fff;
  border: 2px solid;
  box-shadow: 0 0 0 2px #7e8382;
}

.right {
  float: right;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 23px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

ul {
  cursor: pointer;
}

.slide-down-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-down-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-down-enter-to, .slide-down-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-down-enter, .slide-down-leave-to {
  overflow: hidden;
  max-height: 0;
}input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.flip-list-move {
  transition: transform 0.8s ease;
}

.flexible {
  display: flex;
  /*list-style-type: none;*/
  /*padding: 0;*/
  /*justify-content: flex-end;*/
}

